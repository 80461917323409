import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
  Switch as MuiSwitch,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  incentiveNavActionHandlerCreator,
  incentiveNavSearchQueryCreator,
  incentiveNavToggleCreator,
} from '@app/screens/incentive-configuration/redux';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {API_PATH} from '@app/common/constants';

import {Search} from '@mui/icons-material';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import {makeStyles, styled} from '@mui/styles';
import './style.css';
import downLoad from '@app/assets/images/svg/download.svg';
import download_gray_icon from '@app/assets/images/download_gray_icon.png';
import {
  inactiveNavbaFilterIconCreator,
  incentiveActions,
  incentivePageLists,
  incentiveSaveCreator,
} from '@app/screens/incentive-configuration/redux/slice';
import ClearIcon from '@app/assets/svg/clear';
import RefershIcon from '@app/assets/svg/refersh-icon';
import DownLoadIcon from '@app/assets/svg/down-load-icon';
import UploadIcon from '@app/assets/svg/upload-icon';
import {useTheme} from '@mui/material/styles';
import {ConfirmationDialog} from '@app/components/widgets/ConfirmationDialog';
import { downloadExcelFile } from '@app/services/download';

const useStyles = makeStyles((theme: any) => ({
  search: {
    '& input': {
      padding: '12px 30px 12px 0',
      // color: theme.palette.primary.main,
      // '::placeholder': theme.palette.primary.main,
    },
  },
}));

const StyledSwitch = styled(MuiSwitch)(({theme}) => ({
  width: 36,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '9px 0 0 1px',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    backgroundColor: '#ACACAC',
  },
  '& .MuiSwitch-track': {
    height: 20,
    width: 36,
    borderRadius: 20,
    backgroundColor: '#322b7c',
    opacity: 1,
    '&.Mui-checked + &': {
      opacity: 1,
    },
  },
}));

const IncentiveNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchPlaceHolder, setSearchPlaceHolder] = useState<any>('');
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const [showDownloadConfirmation, setShowDownloadConfirmation] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const title = useSelector(incentiveSelector.getNavTitle());
  const action = useSelector(incentiveSelector.getNavAction());
  const search = useSelector(incentiveSelector.getNavSearch());
  const status = useSelector(incentiveSelector.getStatus, shallowEqual);
  const totalCount = useSelector(incentiveSelector.totalRecords, shallowEqual);
  const salesTransferData = useSelector(
    incentiveSelector.getSalesTransferData(),
  );
  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  const currentPage = useSelector(
    incentiveSelector.getCurrentPage,
    shallowEqual,
  );
  const [isSearchable, setIsSearchable] = useState(true);
  const showToggle = useSelector(
    incentiveSelector.getShowNavToggle(),
    shallowEqual,
  );
  const statusOfEditable = useSelector(
    incentiveSelector.clickOnGoBtnValue(),
    shallowEqual,
  );
  const isFilterIconStatus = useSelector(incentiveSelector.getNavFilterIcon());

  const download = useSelector(incentiveSelector.getNavDownload());
  const isSalesDistribution = useSelector(
    incentiveSelector.getNavIsSalesDistribution(),
  );

  const openUploadMenu = (event: React.MouseEvent<HTMLLabelElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeUploadMenu = () => {
    setAnchorEl(null);
  };

  const onSearchHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(incentiveNavSearchQueryCreator(event.target.value));
  };

  const onToggleHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(incentiveNavToggleCreator(event.target.checked));
  };

  const onActionHandler = () => {
    dispatch(incentiveNavActionHandlerCreator({show: true, type: action.type}));
  };

  useEffect(() => {
    if (download?.search?.placeholder) {
      setSearchPlaceHolder(download?.search?.placeholder);
    }
  }, [download]);

  const onActionFilterIconHandler = () => {
    if (isFilterIconStatus?.gobtnClick) {
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          color: 'blue',
          gobtnClick: true,
        }),
      );
    } else {
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          color: isFilterIconStatus?.color === 'blue' ? 'gray' : 'blue',
        }),
      );
    }
  };

  const onSaveHandler = () => {
    dispatch(incentiveSaveCreator({value: 'save'}));
  };

  const onRefreshHandler = () => {
    dispatch(incentiveSaveCreator({value: 'refresh'}));
  };

  const onsubmitHandle = () => {
    dispatch(incentiveSaveCreator({value: 'submit'}));
  };

  const salesTransferURL = useSelector(
    incentiveSelector.getDownloadExcelSalesTransferURL(),
    shallowEqual,
  );

  const salesDistributionURL = useSelector(
    incentiveSelector.getDownloadExcelSalesDistributionURL(),
    shallowEqual,
  );

  const url = () => {
    switch (currentPage) {
      case incentivePageLists.salesTransfer:
        return salesTransferURL;
      case incentivePageLists.salesDistribution:
        return salesDistributionURL;

      default:
        return '';
    }
  };

  const isBtnDisble = useCallback(() => {
    const data = isSalesDistribution
      ? salesDistributionDataList
      : salesTransferData;
    let flag = false;
    if (
      data === null ||
      data?.length === 0 ||
      data.isLocked
    ) {
      flag = true;
    }
    if (isSalesDistribution && !flag) {
      flag = salesDistributionDataList.isSubmit;
    }
    return flag;
  }, [salesTransferData, isSalesDistribution, salesDistributionDataList]);

  const handleDownloadExcel = () => {
   if(isSalesDistribution) {
    setShowDownloadConfirmation(true);
   } else {
    dispatch(
      inactiveNavbaFilterIconCreator({
        clickOnUploadBtn: true,
      }),
    );
   }
  };

  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleUploadExcel = (e: any) => {
    (async () => {
      const {files} = e.target;
      const filename = files[0]?.name;
      let parts = filename?.split('.');
      const fileType = parts[parts?.length - 1];
    })();
  };

  let openFile = () => {
    closeUploadMenu();
    inputFile?.current?.click();
  };

  useEffect(() => {
    if (
      salesTransferData?.segmentList &&
      salesTransferData?.segmentList?.length > 0 &&
      !search?.quary
    ) {
      setIsSearchable(false);
    }

    if (
      salesDistributionDataList?.mrDetails &&
      salesDistributionDataList?.mrDetails?.length > 0 &&
      !search?.quary
    ) {
      setIsSearchable(false);
    }
  }, [
    search?.query,
    salesTransferData,
    isSearchable,
    salesDistributionDataList,
  ]);

  const onClearHandler = () => {
    setShowClearConfirmation(false);
    if(isSalesDistribution) {
      dispatch(incentiveActions.setClearSalesDistributionData(true));
    } else {
      dispatch(incentiveSaveCreator({value: 'clear'}));
    }
  };

  const onDownloadHandler = async () => {
    setShowDownloadConfirmation(false);
      const params = {
        staffPositionIds: [],
        quarterIds: [],
        groupPoolIds: [],
        divisionIds: [],
      };
      setIsDownloading(true);
      await downloadExcelFile(
        API_PATH.incentiveConfiguration.fetchDownloadExcelSalesDistributionURL,
        params,
      );
      setIsDownloading(false);
  }

  const handleChange = (e: any) => {
    const files = e.target.files;
  };

  const downloadToggleHandler = useCallback(() => {}, []);

  const isSubmitButtonDisabled =
    !salesDistributionDataList?.showSubmitButton ||
    salesDistributionDataList?.isSubmit ||
    salesDistributionDataList.isLocked;

  return (
    <>
      <Grid container justifyContent={'space-between'} sx={{height: 100}}>
        <Grid item xs={download?.save ? 2 : 8}>
          <Box
            sx={{
              mt: 1,
              mb: 4,
              minWidth: 200,
            }}>
            <Typography gridTemplateAreas="title" color={'primary.main'}>
              {title} {Boolean(totalCount) && `( ${totalCount} )`}
            </Typography>
          </Box>
          {search && !download?.search ? (
            <TextField
              // disabled={isSearchable}
              variant="outlined"
              placeholder={search?.placeholder}
              className={classes.search}
              type="search"
              fullWidth
              value={search?.query}
              InputProps={{
                className: 'input-addPage',
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={onSearchHandler}
            />
          ) : null}
        </Grid>
        <Grid
          item
          display={'flex'}
          direction="column"
          justifyContent={'space-between'}
          alignContent={'flex-end'}>
          <Grid
            item
            display={'flex'}
            direction="row"
            justifyContent={'space-between'}>
            <Grid container justifyContent={'center'} alignItems={'center'}>
              {action ? (
                <Grid item>
                  <Button variant="contained" onClick={onActionHandler}>
                    {action.title}
                  </Button>
                </Grid>
              ) : null}
              {download?.clear && (
                <Grid item sx={{marginRight: 1.5}}>
                  <Button
                    className="btn_icon"
                    onClick={() => setShowClearConfirmation(true)}
                    disabled={isBtnDisble()}>
                    <ClearIcon color={isBtnDisble() ? '#acacac' : '#322B7C'} />
                  </Button>
                </Grid>
              )}
              {download?.refresh && (
                <Grid item sx={{marginRight: 1.5}}>
                  <Button
                    className="btn_icon"
                    // variant="outlined"
                    onClick={onRefreshHandler}
                    disabled={!statusOfEditable?.isRefreshEditable}>
                    <RefershIcon
                      color={isBtnDisble() ? '#acacac' : '#322B7C'}
                    />
                  </Button>
                </Grid>
              )}

              {download?.upload ? (
                <Grid item sx={{marginRight: 1.5}}>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeUploadMenu}
                    sx={{mt: 2}}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                    <MenuItem
                      sx={{
                        fontSize: 14,
                        borderBottom: '1px solid rgba(112, 144, 176, 0.32)',
                        color: '#322B7C',
                      }}
                      onClick={openFile}>
                      Upload Excel
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: 14,
                        color: '#322B7C',
                      }}
                      onClick={closeUploadMenu}>
                      Download Excel Template
                    </MenuItem>
                  </Menu>
                  <input
                    style={{
                      display: 'none',
                    }}
                    accept=".json"
                    id="choose-file"
                    type="file"
                    onChange={handleUploadExcel}
                    ref={inputFile}
                    className="uploadBtn"
                  />
                  <label
                    htmlFor="choose-file"
                    onClick={openUploadMenu}
                    className="uploadBtn">
                    <IconButton
                      disabled={isBtnDisble()}
                      style={{padding: 0}}
                      aria-label="upload"
                      className="uploadBtn">
                      <UploadIcon
                        color={isBtnDisble() ? '#acacac' : '#322B7C'}
                      />
                    </IconButton>
                  </label>
                </Grid>
              ) : null}

              {download ? (
                <Grid item sx={{marginRight: 1.5}}>
                  <Button
                    className="btn_icon"
                    // variant="contained"
                    onClick={handleDownloadExcel}
                    // disabled={isBtnDisble()}
                  >
                    <DownLoadIcon color={'#322B7C'} />
                  </Button>
                </Grid>
              ) : null}

              {download?.save ? (
                <Grid item sx={{}}>
                  <Button
                    className="save_button"
                    variant="contained"
                    onClick={onSaveHandler}
                    sx={{padding: '11px'}}
                    style={{padding: 11}}
                    disabled={!statusOfEditable?.isEdited}>
                    {download?.save}
                  </Button>
                </Grid>
              ) : null}

              {download?.submit ? (
                <Grid item sx={{marginLeft: 2}}>
                  <Button
                    className="refresh_button"
                    variant="outlined"
                    onClick={onsubmitHandle}
                    sx={{
                      '&.Mui-disabled': {
                        borderWidth: 0,
                      },
                    }}
                    disabled={isSubmitButtonDisabled}>
                    {download?.submit}
                  </Button>
                </Grid>
              ) : null}
              {download?.filter === 'filter' ? (
                <button
                  type="button"
                  onClick={onActionFilterIconHandler}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onActionFilterIconHandler();
                    }
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: '6px',
                    height: 40,
                    border: 'none',
                    background: 'none',
                  }}
                  aria-label={
                    isFilterIconStatus?.color
                      ? 'Download Icon'
                      : 'Gray Download Icon'
                  }>
                  <img
                    src={
                      isFilterIconStatus?.color == 'blue'
                        ? downLoad
                        : download_gray_icon
                    }
                    alt={
                      isFilterIconStatus?.color
                        ? 'Download Icon'
                        : 'Gray Download Icon'
                    }
                    style={{width: '100%', height: '100%'}}
                  />
                </button>
              ) : null}
            </Grid>
          </Grid>

          {showToggle ? (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                mt: 3,
              }}
              alignItems="center"
              alignSelf={'flex-end'}>
              <Typography variant="switch">InActive</Typography>
              <Switch
                sx={{
                  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
                    transform: 'translateX(16px)',
                  },
                  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                    {
                      opacity: 1,
                    },
                }}
                defaultChecked
                inputProps={{'aria-label': 'ant design'}}
                checked={status == 1}
                onChange={onToggleHandler}
              />
              <Typography variant="switch">Active</Typography>
            </Stack>
          ) : null}
        </Grid>
        {download?.showSearchBarWithToggle ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <Box sx={{width: '100%', marginRight: 5}}>
              {download?.search ? (
                <TextField
                  disabled={isSearchable}
                  variant="outlined"
                  placeholder={searchPlaceHolder || ''}
                  className={classes.search}
                  type="search"
                  fullWidth
                  value={search.query}
                  InputProps={{
                    className: 'input-addPage',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={onSearchHandler}
                />
              ) : null}
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{}}
                alignItems="center"
                alignSelf={'flex-end'}>
                <Typography variant="switch">List</Typography>
                <StyledSwitch
                  // sx={{
                  //   '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
                  //     transform: 'translateX(16px)',
                  //   },
                  //   '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                  //   {
                  //     opacity: 1,
                  //   },
                  // }}
                  sx={{
                    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
                      transform: 'translateX(16px)',
                    },
                    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                      {
                        opacity: 1,
                      },
                  }}
                  defaultChecked
                  inputProps={{'aria-label': 'ant design'}}
                  checked={status == 1}
                  onChange={onToggleHandler}
                />
                <Typography variant="switch">Download</Typography>
              </Stack>
            </Box>
          </Box>
        ) : null}
      </Grid>
      {isDownloading ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            bottom: '-167px'
          }}>
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : null}
      <ConfirmationDialog
        open={showClearConfirmation}
        onClose={(status: boolean) => {
          setShowClearConfirmation(false);
          if (status) onClearHandler();
        }}
        message="Are you sure you want to delete complete data for the selected filter combination?"
      />
      <ConfirmationDialog
        open={showDownloadConfirmation}
        onClose={(status: boolean) => {
          setShowDownloadConfirmation(false);
          if (status) onDownloadHandler();
        }}
        message="You have requested to download the complete data for current financial year. Do you want to Continue?"
      />
    </>
  );
};

export default React.memo(IncentiveNavBar);
