import React, {useCallback, useEffect, useState} from 'react';
import {incentiveActions} from '../../redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DatePickerComponent from '@app/components/date-picker/DatePickerComponent';
import Dropdown from '@app/components/dropdown';
import {Styles} from './stlye';
import dayjs from 'dayjs';
import {incentiveSelector} from '../../redux/selectors';
import {unlockRequestCreator} from '../../pages/unlock-request/redux';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MultiSelectDropDown from '@app/components/multiSelectDropDown';

enum FilterType {
  division = 'division',
  state = 'state',
  unlockTillDate = 'unlockTillDate',
  requestFor = 'requestFor',
}

const styles = {
  listItemText: {
    color: '#1e1c3b',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    marginLeft: 1,
  },
  placeHolder: {
    color: '#1C19393B',
    fontStyle: 'normal',
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

let temp: any[] = [];

function FilterHeader(
  props: Readonly<{changeFilter: Function; clearFilter: Function; containerStyle?: any}>,
) {
  const [date, setDate] = useState<Date | null>(null);
  const [state, setState] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [division, setDivision] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [request, setRequest] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [selectedFilter, setSelectedFilter] = useState({
    unlockTillDate: null,
    stateId: null,
    divisionId: null,
    requestedFor: null,
  });

  const [selectedState, setSelectedState] = useState<any>([]);
  const [divisionName, setDivisionName] = useState<string[]>([]);
  const [selectDivisionAll, setSelectDivisionAll] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState<any>([]);
  const [searchDivisionTxt, setSearchDivisionTxt] = useState<string>('');
  const [divisionListArr, setDivisionListArr] = useState<any>([]);

  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const stateList = useSelector(incentiveSelector.getStateList());
  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const requestList = useSelector(incentiveSelector.getRequestList());
  const clearFilterData = useSelector(incentiveSelector.clearFilterData());

  useEffect(() => {
    if (clearFilterData) {
      resetData();
      props.clearFilter();
    }
  }, [clearFilterData]);

  const dispatch = useDispatch();

  const resetData = () => {
    setDate(null);
    setState(undefined);
    setRequest(undefined);
    setDivision(undefined);
    setSelectedState([]);
    setSelectedDivision([]);
    dispatch(incentiveActions.toggleClearFilter(false));
  };

  const findIdList = (arrayOfObj: any, arrayValue: any) => {
    const result = arrayValue.map((label: any) => {
      const item = arrayOfObj.find((entry: any) => entry.label === label);
      return item ? item.value : 0;
    });

    return result;
  };

  const filterHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      const divisionIdList: any[] = findIdList(divisionList, selectedDivision);
      const stateIdList: any[] = findIdList(stateList, selectedState);

      const dataParam = {
        pageIndex: 1,
        isActive: isActive,
        divisionIdList: divisionIdList,
        stateIdList: stateIdList,
        requestedFor: request?.label,
        unlockTillDate: date ? dayjs(date).format() : null,
      };
      dispatch(unlockRequestCreator(dataParam));
    },
    [
      division,
      state,
      request,
      date,
      isActive,
      selectedDivision,
      divisionList,
      selectedState,
    ],
  );

  const selectfilterHandler = useCallback(
    (val: any, typeOfFilter: FilterType) => {
      let value: any;
      switch (typeOfFilter) {
        case FilterType.division:
          value = {
            ...selectedFilter,
            divisionId: val?.value,
          };
          break;
        case FilterType.state:
          value = {
            ...selectedFilter,
            stateId: val?.value,
          };
          break;
        case FilterType.unlockTillDate:
          value = {
            ...selectedFilter,
            unlockTillDate: val,
          };
          break;
        case FilterType.requestFor:
          value = {
            ...selectedFilter,
            requestedFor: val?.label,
          };
          break;
      }
      setSelectedFilter(value);
    },
    [selectedFilter, props],
  );

  const renderStateValue = useCallback(selected => {
    if (selected.length === 0) {
      return <em style={styles.placeHolder}>Select Division</em>;
    }
    return selected.join(', ');
  }, []);

  const handleDivision = useCallback(
    (event: any) => {
      const {
        target: {value},
      } = event;

      setDivisionName(typeof value === 'string' ? value.split(',') : value);

      if (selectedDivision?.length !== event.target.value?.length) {
        setSelectDivisionAll(false);
        setSelectedDivision(event.target.value);
      }

      if (divisionList?.length === event.target.value?.length) {
        setSelectDivisionAll(true);
        setSelectedDivision(event.target.value);
      }
    },
    [selectedDivision, selectDivisionAll, divisionName, divisionList],
  );

  const handleIsSelectedAllDivision = () => {
    if (selectDivisionAll) {
      setSelectDivisionAll(false);
    } else {
      setSelectDivisionAll(true);
    }
  };

  useEffect(() => {
    let temp: any = [];

    if (selectDivisionAll) {
      divisionList?.forEach((element: any) => {
        temp.push(element.label);
      });
      setSelectedDivision(temp);
      setDivisionName(temp);
    }

    if (
      !selectDivisionAll &&
      selectedDivision?.length - 1 === divisionList?.length
    ) {
      setDivisionName([]);
    }
  }, [selectDivisionAll]);

  const handleTextFiled = useCallback((e: any) => {
    let temp = [...divisionList];
    setSearchDivisionTxt(e.target.value);
    let searchdata = temp?.filter((val: any) => {
      return val?.label?.toLowerCase().includes(e.target.value);
    });

    setDivisionListArr(searchdata);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchDivisionTxt('');
    setDivisionListArr(divisionList);
  }, [divisionListArr]);

  useEffect(() => {
    temp = JSON.parse(JSON.stringify(divisionList));
    setDivisionListArr(JSON.parse(JSON.stringify(divisionList)));
  }, [divisionList]);
  return (
    <div style={{...Styles.filterHeaderBody, ...props.containerStyle}}>
      <div style={Styles.filterBox}>
        <Box mr={1} data-testid={'selectDiv-dropdown'}>
          <FormControl sx={{width: 160}}>
            <Select
              multiple
              value={divisionName}
              onChange={handleDivision}
              renderValue={renderStateValue}
              MenuProps={MenuProps}
              displayEmpty
              sx={{backgroundColor: '#FFFFFF'}}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  value={searchDivisionTxt}
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    style: {
                      height: 30,
                      fontSize: 11,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{cursor: 'pointer'}}
                        onClick={clearSearch}
                      >
                        <ClearIcon sx={{fontSize: 15}} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleTextFiled}
                  onKeyDown={e => {
                    if (e.key !== 'Escape') {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem onChange={handleIsSelectedAllDivision}>
                <Checkbox value={'all'} checked={selectDivisionAll} />
                <ListItemText
                  primary={
                    <Typography sx={styles.listItemText}>
                      {selectDivisionAll ? 'UnSelect All' : 'Select All'}
                    </Typography>
                  }
                />
              </MenuItem>
              {divisionListArr?.map((val: any) => (
                <MenuItem key={val?.value} value={val?.label}>
                  <Checkbox checked={divisionName?.indexOf(val.label) > -1} />
                  <ListItemText
                    primary={
                      <Typography sx={styles.listItemText}>
                        {val?.label}
                      </Typography>
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mr={1} data-testid={'selectState-dropdown'}>
          <MultiSelectDropDown
            width={160}
            options={stateList}
            isSearchable={true}
            selectedValues={(e: any) => setSelectedState(e)}
            placeHolder={'Select State'}
          />
        </Box>
        <Box mr={1} width={160} data-testid={'selectDate-picker'}>
          <DatePickerComponent
            disablePast={false}
            onError={() => setDate(null)}
            value={date}
            placeholder="Select Date"
            inputStyle={{padding: '13.1px'}}
            onChange={(val: Date) => {
              if (val) {
                selectfilterHandler(val, FilterType.unlockTillDate);
                setDate(new Date(val));
              } else {
                props.changeFilter({
                  date: null,
                });
                setDate(null);
              }
            }}
          />
        </Box>
        <Box mr={1} width={160} data-testid={'selectRequest-dropdown'}>
          <Dropdown
            onChange={(val: any) => {
              selectfilterHandler(val, FilterType.requestFor);
              setRequest(val);
            }}
            value={request}
            dropdownList={requestList}
            placeholder="Request For"
          />
        </Box>

        <Button
          data-testid="clickGo-icon"
          variant="contained"
          onClick={filterHandler}
          size="small"
          style={{
            fontFamily: ['Poppins'].join(','),
            fontSize: 12.7,
            padding: '11px',
            cursor: 'pointer',
            letterSpacing: 0,
            textAlign: 'center',
            backgroundColor: '#322b7c',
            color: 'white',
            border: 0,
            borderRadius: 10,
            minWidth: 50,
          }}
        >
          Go
        </Button>
      </div>
    </div>
  );
}

export default React.memo(FilterHeader);
