import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

export const ConfirmationDialog = ({
  open,
  onClose,
  message=""
}: {
  open: boolean;
  onClose: (status: boolean) => void;
  message: string;
}) => {
  const handleClose = (event: any, reason: string) => {
    if (reason && reason == 'backdropClick') return;
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center'}}>
        <Button data-testid="button-cancel" onClick={() => onClose(false)}>
          No
        </Button>
        <Button data-testid="button-ok" onClick={() => onClose(true)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
