import { createSelector } from '@reduxjs/toolkit';

const navData = (state: any) => state.incentiveState.nav;
const modalData = (state: any) => state.incentiveState.modal;
const loadingData = (state: any) => state.incentiveState.loading;
const searchData = (state: any) => state.incentiveState.nav.search;
const pagesData = (state: any) => state.incentiveState.pages;
const currentPage = (state: any) => state.incentiveState.currentPage;
const stateList = (state: any) => state.incentiveState.statesList;
const divisionList = (state: any) => state.incentiveState.divisionList;
const reFetchData = (state: any) => state.incentiveState.reFetchData;
const addingResponse = (state: any) => state.incentiveState.addingResponse;
const fetchingResponse = (state: any) => state.incentiveState.fetchingResponse;
const getRequestList = (state: any) => state.incentiveState.requestForList;
const clearFilterData = (state: any) => state.incentiveState.clearFilterData;

// incentive distribution
const quarterData = (state: any) => state.incentiveState.quarterData;
const fromHQData = (state: any) => state.incentiveState.fromHQData;
const toHQData = (state: any) => state.incentiveState.toHQData;
const poolData = (state: any) => state.incentiveState.poolData;
const toPoolData = (state: any) => state.incentiveState.toPoolData;
const filterFromPoolData = (state: any) =>
  state.incentiveState.filterFromPoolData;
const filterToPoolData = (state: any) => state.incentiveState.filterToPoolData;
const groupOfPoolData = (state: any) => state.incentiveState.groupOfPoolData;
const toGroupOfPoolData = (state: any) =>
  state.incentiveState.toGroupOfPoolData;
const slmData = (state: any) => state.incentiveState.slmData;
const salesTransferData = (state: any) =>
  state.incentiveState.salesTransferData;
const salesTransferBackupData = (state: any) =>
  state.incentiveState.salesTransferBackData;
const selectedQuarterListData = (state: any) =>
  state.incentiveState.quarterList;
const loadingSalesTransferData = (state: any) =>
  state.incentiveState.loadingSalesTransferData;
const FilterIcon = (state: any) => state.incentiveState.filterIcon;
const groupPoolList = (state: any) => state.incentiveState.groupPoolList;
const slmSalesDistributionList = (state: any) =>
  state.incentiveState.slmSalesDistributionList;
const poolSalesDistributionList = (state: any) =>
  state.incentiveState.poolSalesDistributionList;
const filterPoolData = (state: any) => state.incentiveState.filterPoolData;
const clickOnGoBtnValu = (state: any) => state.incentiveState.clickOnGoBtnValue;
const auditTrailData = (state: any) => state.incentiveState.auditTrailActionHandlerData
const refetchSalesTransfer = (state: any) => state.incentiveState.refetchSalesTransfer
const refetchSalesDistribution = (state: any) => state.incentiveState.refetchSalesDistribution
const auditTrailExcel = (state: any) => state.incentiveState.auditTrailExcelData
const clearSalesDistribution = (state: any) => state.incentiveState.clearSalesDistribution

/****** sales distribution  */

const salesDistributionListData = (state: any) =>
  state.incentiveState.salesDistributionListData;
const salesDistributionBackupListData = (state: any) =>
  state.incentiveState.salesDistributionBackupListData;
const remaksData = (state: any) => state.incentiveState.remaksData;
const salesDistributionDiscriptionMssg = (state: any) =>
  state.incentiveState.massage;

const downloadExcelSalesTransferURL = (state: any) =>
  state.incentiveState.downloadExcelSalesTransferURL;

const downloadExcelSalesDistributionURL = (state: any) =>
  state.incentiveState.downloadExcelSalesDistributionURL;

const searchQuery = createSelector([searchData], _searchData =>
  _searchData?.query ? _searchData.query : '',
);
const getStatus = createSelector([navData], nav =>
  nav?.status?.value ? 1 : 0,
);

const getSaveStatus = createSelector([navData], nav => nav?.save?.value);

const getShowNavToggle = createSelector([navData], nav => nav?.status?.show);
const getNavTitle = createSelector([navData], nav => nav.title);
const getNavAction = createSelector([navData], nav => nav.action);
const getNavSearch = createSelector([navData], nav => nav.search);
const getNavIsSalesDistribution = createSelector([navData], nav => nav.isSalesDistribution);
const getincentiveModal = createSelector([modalData], modal => modal);
const getLoading = createSelector([loadingData], loading => loading);
const getReFetchData = createSelector(
  [reFetchData],
  reFetchData => reFetchData,
);
const getNavDownload = createSelector([navData], nav => nav.download);
const getCurrentPage = createSelector(
  [currentPage],
  _currentPage => _currentPage,
);
const totalRecords = createSelector(
  pagesData,
  currentPage,
  (_pages, _page) => _pages[_page]?.count || 0,
);
const pageIndex = createSelector(
  pagesData,
  currentPage,
  (_pages, _page) => _pages[_page]?.pageIndex || 1,
);
const getStateList = createSelector([stateList], stateList => stateList);
const getNavFilterIcon = createSelector([FilterIcon], FilterIcon => FilterIcon);
const getDivisionList = createSelector(
  [divisionList],
  divisionList => divisionList,
);
const getFROMHQList = createSelector([fromHQData], fromHQData => fromHQData);
const getToHQList = createSelector([toHQData], toHQData => toHQData);
const getPoolList = createSelector([poolData], poolData => poolData);
const getToPoolList = createSelector([toPoolData], toPoolData => toPoolData);
const getFilterFromPoolData = createSelector(
  [filterFromPoolData],
  filterFromPoolData => filterFromPoolData,
);
const getFilterToPoolData = createSelector(
  [filterToPoolData],
  filterToPoolData => filterToPoolData,
);
const getFromPoolList = createSelector(
  [groupOfPoolData],
  groupOfPoolData => groupOfPoolData,
);
const getToGroupOfPoolList = createSelector(
  [toGroupOfPoolData],
  toGroupOfPoolData => toGroupOfPoolData,
);
const getSLMData = createSelector([slmData], slmData => slmData);
const getSalesTransferData = createSelector(
  [salesTransferData],
  salesTransferData => salesTransferData,
);
const getSalesTransferBackupData = createSelector(
  [salesTransferBackupData],
  salesTransferBackupData => salesTransferBackupData,
);
const getselectedQuarterListData = createSelector(
  [selectedQuarterListData],
  selectedQuarterListData => selectedQuarterListData,
);
const getLoadingSalesTransferData = createSelector(
  [loadingSalesTransferData],
  loadingSalesTransferData => loadingSalesTransferData,
);
const getGroupPoolList = createSelector(
  [groupPoolList],
  groupPoolList => groupPoolList,
);
const getSLMSalesDistributionList = createSelector(
  [slmSalesDistributionList],
  slmSalesDistributionList => slmSalesDistributionList,
);
const getPoolSalesDistributionList = createSelector(
  [poolSalesDistributionList],
  poolSalesDistributionList => poolSalesDistributionList,
);
const getFilterPoolList = createSelector(
  [filterPoolData],
  filterPoolData => filterPoolData,
);
const clickOnGoBtnValue = createSelector(
  [clickOnGoBtnValu],
  clickOnGoBtnValu => clickOnGoBtnValu,
);

/****** sales distribution  */

const getSalesDistributionList = createSelector(
  [salesDistributionListData],
  salesDistributionListData => salesDistributionListData,
);

const getSalesDistributionBackupList = createSelector(
  [salesDistributionBackupListData],
  salesDistributionBackupListData => salesDistributionBackupListData,
);
const getRemaksDataList = createSelector(
  [remaksData],
  remaksData => remaksData,
);
const getSaleDistributionMssg = createSelector(
  [salesDistributionDiscriptionMssg],
  salesDistributionDiscriptionMssg => salesDistributionDiscriptionMssg ?? '',
);

const getDownloadExcelSalesTransferURL = createSelector(
  [downloadExcelSalesTransferURL],
  _downloadExcelSalesTransferURL => _downloadExcelSalesTransferURL,
);

const getDownloadExcelSalesDistributionURL = createSelector(
  [downloadExcelSalesDistributionURL],
  _downloadExcelSalesDistributionURL => _downloadExcelSalesDistributionURL,
);


const getAuditTrailData = createSelector(
  [auditTrailData], auditTrail => auditTrail,
)

const getRefetchSalesTransfer = createSelector(
  [refetchSalesTransfer], refetchSalesTransfer => refetchSalesTransfer,
)

const getRefetchSalesDistribution = createSelector(
  [refetchSalesDistribution], refetchSalesDistribution => refetchSalesDistribution,
)


/*** get AdutTrail download excel for sales transfer screen */

const getDownloadAdutTrailExcelSalesTransferURL = createSelector(
  [auditTrailExcel], auditTrailExcel => auditTrailExcel,
)

const getClearSalesDistribution = createSelector(
  [clearSalesDistribution], clearSalesDistribution => clearSalesDistribution,
)

// incentive distribution

const getQuartetList = createSelector(
  [quarterData],
  quarterData => quarterData,
);
const getFromHQList = createSelector;

export const incentiveSelector = {
  getNavTitle: () => getNavTitle,
  getNavFilterIcon: () => getNavFilterIcon,
  getNavAction: () => getNavAction,
  getincentiveModal: () => getincentiveModal,
  getLoading: () => getLoading,
  getNavSearch: () => getNavSearch,
  getNavIsSalesDistribution: () => getNavIsSalesDistribution,
  searchQuery,
  getStatus,
  getSaveStatus,
  totalRecords,
  pageIndex,
  clickOnGoBtnValue: () => clickOnGoBtnValue,
  getShowNavToggle: () => getShowNavToggle,
  getCurrentPage,
  getStateList: () => getStateList,
  getDivisionList: () => getDivisionList,
  getReFetchData: () => getReFetchData,
  addingResponse: () => addingResponse,
  fetchingResponse: () => fetchingResponse,
  getRequestList: () => getRequestList,
  clearFilterData: () => clearFilterData,
  getQuartetList: () => getQuartetList,
  getFROMHQList: () => getFROMHQList,
  getToHQList: () => getToHQList,
  getSLMData: () => getSLMData,
  getPoolData: () => getPoolList,
  getToPoolData: () => getToPoolList,
  getGrpOfPoolData: () => getFromPoolList,
  getSalesTransferData: () => getSalesTransferData,
  getSalesTransferBackupData: () => getSalesTransferBackupData,
  getselectedQuarterListData: () => getselectedQuarterListData,
  getLoadingSalesTransferData: () => getLoadingSalesTransferData,
  getNavDownload: () => getNavDownload,
  getGroupPoolList: () => getGroupPoolList,
  getSLMSalesDistributionList: () => getSLMSalesDistributionList,
  getPoolSalesDistributionList: () => getPoolSalesDistributionList,
  getFilterPoolList: () => getFilterPoolList,
  getFilterFromPoolData: () => getFilterFromPoolData,
  getFilterToPoolData: () => getFilterToPoolData,
  getToGroupOfPoolList: () => getToGroupOfPoolList,
  getSalesDistributionList: () => getSalesDistributionList,
  getSalesDistributionBackupList: () => getSalesDistributionBackupList,
  getRemaksDataList: () => getRemaksDataList,
  getSaleDistributionMssg,
  getDownloadExcelSalesTransferURL: () => getDownloadExcelSalesTransferURL,
  getDownloadExcelSalesDistributionURL: () =>
    getDownloadExcelSalesDistributionURL,
  getAuditTrailData: () => getAuditTrailData,
  getRefetchSalesTransfer: () => getRefetchSalesTransfer,
  getRefetchSalesDistribution: () => getRefetchSalesDistribution,
  getDownloadAdutTrailExcelSalesTransferURL: () => getDownloadAdutTrailExcelSalesTransferURL,
  getClearSalesDistribution: () => getClearSalesDistribution
};
