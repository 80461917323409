import * as React from 'react';

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  MonthType,
  SegmentList,
} from '@app/screens/incentive-configuration/types';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandedCell from './ExpandedCell';
import If from '@app/components/If';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getGrandTotal} from '@app/screens/incentive-configuration/helper';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import {styles} from './style';
import {useSelector} from 'react-redux';

interface SalesTransferTableProps {
  showDisclaimer: any;
  isEmpty: any;
  onUpdateHandler: (data: any) => any;
  salesTransferAllData: any;
}

export const SalesTransferTable: React.FC<SalesTransferTableProps> = ({
  isEmpty,
  onUpdateHandler,
  salesTransferAllData,
}) => {
  const [unmount, setUnmount] = React.useState(true);
  const quarterList: any = useSelector(
    incentiveSelector.getselectedQuarterListData(),
  );
  const isFilterIconStatus: any = useSelector(
    incentiveSelector.getNavFilterIcon(),
  );

  return (
    <>
      {isEmpty ? (
        <Box
          sx={{color: '#322b7c', fontSize: 12, fontWeight: 500, marginLeft: 5}}>
          {'No Record Found'}
        </Box>
      ) : (
        <Box sx={{p: 2}}>
          {
            <>
              <TableContainer
                sx={{
                  maxHeight: '45vh',
                  // minHeight: '45vh',
                }}>
                <Table stickyHeader aria-label="sticky table" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      {salesTransferAllData.isProductView ? (
                        <>
                          <TableCell
                            style={{...styles.groupHeder, paddingLeft: 13}}>
                            Product
                          </TableCell>
                          <TableCell
                            style={{...styles.groupHeder, paddingLeft: 13}}>
                            Pack
                          </TableCell>
                          <TableCell
                            style={{...styles.groupHeder, paddingLeft: 13}}>
                            Code
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            style={{...styles.groupHeder, paddingLeft: 13}}>
                            Group
                          </TableCell>
                          <TableCell align="left" style={styles.tableHeader}>
                            INC%
                          </TableCell>
                        </>
                      )}
                      {quarterList?.month?.map((month: any, index: any) => {
                        const index_key = index.toString();
                        return (
                          <TableCell
                            key={index_key}
                            align="left"
                            style={styles.tableHeader}>
                            {month?.name}
                            <Typography
                              sx={{
                                fontSize: '9px',
                                color: '#1C1939',
                                fontWeight: 600,
                              }}>
                              {salesTransferAllData.isProductView
                                ? '(In Qty)'
                                : '(In Rs)'}
                            </Typography>
                          </TableCell>
                        );
                      })}
                      <TableCell
                        align="left"
                        style={{
                          ...styles.totalHeader,
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                        }}>
                        Total Sale
                        <Typography
                          sx={{
                            fontSize: '9px',
                            color: '#1C1939',
                            fontWeight: 600,
                          }}>
                          {salesTransferAllData.isProductView
                            ? '(In Qty)'
                            : '(In Rs)'}
                        </Typography>
                      </TableCell>
                      {salesTransferAllData.isProductView ? null : (
                        <TableCell align="left" style={styles.tableHeader}>
                          Incentive
                          <Typography
                          sx={{
                            fontSize: '9px',
                            color: '#1C1939',
                            fontWeight: 600,
                          }}>
                          {salesTransferAllData.isProductView
                            ? '(In Qty)'
                            : '(In Rs)'}
                        </Typography>
                        </TableCell>
                      )}
                      <TableCell align="left" style={styles.remarkHeader}>
                        Remark
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!isEmpty ? (
                    <TableBody>
                      {salesTransferAllData?.segmentList?.map(
                        (row: SegmentList) => (
                          <ExpandableTableRow
                            key={row.id}
                            expandComponent={
                              salesTransferAllData.isProductView
                                ? row?.subProduct
                                : row?.subSegment
                            }
                            unmount={unmount}
                            onUpdateHandler={onUpdateHandler}
                            salesTransferAllData={salesTransferAllData}
                            onUnMount={(val: boolean) => {
                              setUnmount(val);
                            }}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                fontWeight: 500,
                                paddingLeft: 12,
                                paddingRight: 12,
                              }}>
                              {row?.name}
                            </TableCell>

                            {salesTransferAllData.isProductView ? (
                              <>
                                <TableCell
                                  style={{fontWeight: 700}}></TableCell>
                                <TableCell
                                  style={{fontWeight: 700}}></TableCell>
                              </>
                            ) : (
                              <TableCell style={{fontWeight: 700}}></TableCell>
                            )}

                            <TableCell>
                              <div
                                style={{
                                  backgroundColor: '#e9e9e9',
                                  borderRadius: 5,
                                  height: 26,
                                  minWidth: 85,
                                  textAlign: 'right',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                }}>
                                {Number(row.month1).toFixed(0)}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  backgroundColor: '#e9e9e9',
                                  borderRadius: 5,
                                  height: 26,
                                  minWidth: 85,
                                  textAlign: 'right',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                }}>
                                {Number(row.month2).toFixed(0)}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  backgroundColor: '#e9e9e9',
                                  borderRadius: 5,
                                  height: 26,
                                  minWidth: 85,
                                  textAlign: 'right',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                }}>
                                {Number(row.month3).toFixed(0)}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  backgroundColor: '#e9e9e9',
                                  borderRadius: 5,
                                  height: 26,
                                  minWidth: 80,
                                  textAlign: 'right',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                }}>
                                {(
                                  Number(row.month1) +
                                  Number(row.month2) +
                                  Number(row.month3)
                                ).toFixed(0)}
                              </div>
                            </TableCell>
                            {salesTransferAllData.isProductView ? null : (
                              <TableCell>
                                <div
                                  style={{
                                    backgroundColor: '#e9e9e9',
                                    borderRadius: 5,
                                    height: 26,
                                    minWidth: 80,
                                    textAlign: 'right',
                                    padding: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    fontWeight: 500,
                                  }}>
                                  {Number(row.incentiveAmount).toFixed(2)}
                                </div>
                              </TableCell>
                            )}
                            <TableCell>
                              <div style={{backgroundColor: '#e9e9e9'}}></div>
                            </TableCell>
                          </ExpandableTableRow>
                        ),
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableCell colSpan={3}>
                        <div>No Record Found</div>
                      </TableCell>
                    </TableBody>
                  )}
                  <TableFooter
                    style={{left: 0, bottom: 0, zIndex: 2, position: 'sticky'}}>
                    <TableRow style={{backgroundColor: '#2fb4ff'}}>
                      <TableCell padding="checkbox" />
                      <TableCell
                        colSpan={2}
                        style={{
                          color: '#ffffff',
                          fontSize: 12,
                          fontWeight: 700,
                        }}>
                        Grand total
                      </TableCell>
                      {salesTransferAllData.isProductView ? (
                        <TableCell style={{fontWeight: 700}} />
                      ) : null}
                      <TableCell align="left">
                        <div
                          style={{
                            backgroundColor: '#94d8ff',
                            borderRadius: 5,
                            height: 26,
                            minWidth: 90,
                            textAlign: 'right',
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 500,
                            color: '#322b7c',
                          }}>
                          {getGrandTotal(
                            salesTransferAllData?.segmentList,
                            MonthType.month1,
                          ).toFixed(0)}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            backgroundColor: '#94d8ff',
                            borderRadius: 5,
                            height: 26,
                            minWidth: 90,
                            textAlign: 'right',
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 500,
                            color: '#322b7c',
                          }}>
                          {getGrandTotal(
                            salesTransferAllData?.segmentList,
                            MonthType.month2,
                          ).toFixed(0)}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            backgroundColor: '#94d8ff',
                            borderRadius: 5,
                            height: 26,
                            minWidth: 90,
                            textAlign: 'right',
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 500,
                            color: '#322b7c',
                          }}>
                          {getGrandTotal(
                            salesTransferAllData?.segmentList,
                            MonthType.month3,
                          ).toFixed(0)}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            backgroundColor: '#94d8ff',
                            borderRadius: 5,
                            height: 26,
                            minWidth: 90,
                            textAlign: 'right',
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 500,
                            color: '#322b7c',
                          }}>
                          {getGrandTotal(
                            salesTransferAllData?.segmentList,
                            MonthType.month1,
                          ) +
                            getGrandTotal(
                              salesTransferAllData?.segmentList,
                              MonthType.month2,
                            ) +
                            getGrandTotal(
                              salesTransferAllData?.segmentList,
                              MonthType.month3,
                            )}
                        </div>
                      </TableCell>
                      {salesTransferAllData.isProductView ? null : (
                        <TableCell align="left">
                          <div
                            style={{
                              backgroundColor: '#94d8ff',
                              borderRadius: 5,
                              height: 26,
                              minWidth: 90,
                              textAlign: 'right',
                              padding: 5,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              fontWeight: 500,
                              color: '#322b7c',
                            }}>
                            {getGrandTotal(
                              salesTransferAllData?.segmentList,
                              MonthType.incentive,
                            ).toFixed(2)}
                          </div>
                        </TableCell>
                      )}
                      <TableCell align="left">
                        <div
                          style={{
                            borderRadius: 5,
                            height: 26,
                            minWidth: 80,
                            width: 80,
                            textAlign: 'right',
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 500,
                            color: '#322b7c',
                          }}></div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <If condition={salesTransferAllData?.showDisclaimer}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 10,
                  }}>
                  <div>
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        fontSize: 12,
                        color: 'red',
                      }}>
                      Disclaimer -
                    </span>
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        fontSize: 12,
                        color: 'red',
                      }}>
                      {salesTransferAllData?.disclaimer}
                    </span>
                  </div>
                </div>
              </If>
            </>
          }
        </Box>
      )}
    </>
  );
};

const ExpandableTableRow = (props: any) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const getChild = React.useMemo(() => {
    let txt = props?.salesTransferAllData?.isProductView
      ? 'subProduct'
      : 'subSegment';
    return (
      <React.Fragment>
        {props?.expandComponent?.map(
          (row: Omit<SegmentList, typeof txt>, index: any) => {
            const index_key = index?.toString();
            return (
              <ExpandedCell
                key={index_key}
                row={row}
                onUnMount={props?.onUnMount}
                unmount={props?.unmount}
                onUpdateHandler={props?.onUpdateHandler}
              />
            );
          },
        )}
      </React.Fragment>
    );
  }, [open, props]);
  return (
    <>
      <TableRow
        {...props.otherProps}
        style={{backgroundColor: 'rgba(213, 226, 231, 0.32)'}}>
        <TableCell padding="checkbox">
          {props?.expandComponent?.length > 0 ? (
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              <If condition={isExpanded}>
                <RemoveCircleOutlineIcon style={{color: '#322b7c'}} />
              </If>
              <If condition={!isExpanded}>
                <AddCircleOutlineIcon style={{color: '#322b7c'}} />
              </If>
            </IconButton>
          ) : null}
        </TableCell>
        {props.children}
      </TableRow>
      {isExpanded && getChild}
    </>
  );
};
