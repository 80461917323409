import {DropDownValue, SalesTransferData, AuditTrail} from '../types';
import {addTotalInSegmentList, getFormattedRequest} from '../helper';
import {createAction, createSlice} from '@reduxjs/toolkit';

export const actions = {
  addUnlockRequest: 'addUnlockRequest',
  addCutOffDateConfiguration: 'addCutOffDateConfiguration',
  downloadSalesDistribution: 'downloadSalesDistribution',
  addSalesTransferConfiguration: 'addSalesTransferConfiguration',
  addSalesTransfer: 'addSalesTransfer',
  filterIconShow: 'filterIconShow',
};

export const incentivePageLists = {
  unlockRequest: 'unlockRequest',
  cutOffDateConfiguration: 'cutOffDateConfiguration',
  salesDistribution: 'salesDistribution',
  salesTransfer: 'salesTransfer',
  salesTransferConfiguration: 'salesTransferConfiguration',
};

interface initialStateProps {
  nav: Record<string, any>;
  pages: Record<string, Record<string, any>>;
  modal: {};
  loading: null | number | boolean;
  currentPage: string | null;
  statesList: DropDownValue[] | null;
  divisionList: any;
  reFetchData: boolean;
  addingResponse: boolean;
  fetchingResponse: boolean;
  requestForList: DropDownValue[] | null;
  clearFilterData: boolean;
  salesTransferData: SalesTransferData | null;
  salesTransferBackData: SalesTransferData | null;
  quarterData: any;
  fromHQData: DropDownValue[] | null;
  toHQData: DropDownValue[] | null;
  slmData: DropDownValue[] | null;
  PoolData: DropDownValue[] | null;
  filterFromPoolData: DropDownValue[] | null;
  filterToPoolData: DropDownValue[] | null;
  toGroupOfPoolData: DropDownValue[] | null;
  groupOfPoolData: DropDownValue[] | null;
  quarterList: any;
  loadingSalesTransferData: boolean;
  groupPoolList: DropDownValue[] | null;
  slmSalesDistributionList: DropDownValue[] | null;
  poolSalesDistributionList: DropDownValue[] | null;
  filterPoolData: DropDownValue[] | null;
  clickOnGoBtnValue: any;
  salesDistributionListData: any;
  salesDistributionBackupListData: any;
  remaksData: DropDownValue[] | null;
  downloadExcelSalesTransferURL: '';
  downloadExcelSalesDistributionURL: '';
  auditTrailActionHandlerData: {
    data : AuditTrail
  },
  refetchSalesTransfer: boolean | number;
  refetchSalesDistribution: boolean | number;
  auditTrailExcelData: '';
  clearSalesDistribution: boolean;
}

const initialState: initialStateProps = {
  nav: {
    title: null,
    action: {
      title: null,
      type: null,
    },
    search: {
      placeholder: 'Search here',
      query: '',
    },
    status: {
      show: false,
      value: true,
    },
    download: {
      title: null,
      type: null,
    },
    filterIocn: {
      show: false,
      value: true,
    },
    save: {
      value: '',
    },
  },
  pages: {
    unlockRequest: {
      count: 0,
      pageIndex: 1,
    },
  },
  modal: {
    show: null,
    type: null,
  },
  loading: null,
  currentPage: null,
  statesList: null,
  divisionList: null,
  reFetchData: false,
  addingResponse: false,
  fetchingResponse: false,
  requestForList: null,
  clearFilterData: false,
  salesTransferData: null,
  salesTransferBackData: null,
  quarterData: [],
  fromHQData: [],
  toHQData: [],
  slmData: [],
  PoolData: [],
  filterFromPoolData: [],
  filterToPoolData: [],
  toGroupOfPoolData: [],
  groupOfPoolData: [],
  quarterList: null,
  loadingSalesTransferData: false,
  groupPoolList: [],
  slmSalesDistributionList: [],
  poolSalesDistributionList: [],
  filterPoolData: [],
  clickOnGoBtnValue: {
    val: 0,
    isEdited: false,
  },
  salesDistributionListData: undefined,
  salesDistributionBackupListData: undefined,
  remaksData: [],
  downloadExcelSalesTransferURL: '',
  downloadExcelSalesDistributionURL: '',
  auditTrailActionHandlerData: {
    data: {
      id: 0,
      salesTransferId: 0,
      staffPositionId: 0,
      quarterId: 0,
      fromPoolId: 0,
      fromGroupPoolId: 0,
      toPoolId: 0,
      toGroupPoolId: 0,
      isActive: false,
      actionType: '',
      createdBy: 0,
      createdByName: '',
      createdOn: '', 
      modifiedBy: 0,
      modifiedByName: '',
      modifiedOn: '',
      isDeleted: false,
      deletedDate: '', 
      deletedBy: 0,
      deletedByName: '',
    }
  },
  refetchSalesTransfer: false,
  refetchSalesDistribution: false,
  auditTrailExcelData: '',
  clearSalesDistribution: false,
};


export const incentiveAuditTrailExcelCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/UPDATE_INCENTIVE_ADUTTRAIL_EXCEL',
);

export const incentiveAuditTrailExcelCreatorTypeName =
incentiveAuditTrailExcelCreator(null).type;

export const incentiveAuditTrailCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/UPDATE_INCENTIVE_NAV_ACTION_HANDLR',
);

export const incentiveAuditTrailHandlerCreatorTypeName =
incentiveAuditTrailCreator(null).type;


export const incentiveNavActionHandlerCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/UPDATE_INCENTIVE_NAV_ACTION_HANDLR',
);

export const incentiveNavActionHandlerCreatorTypeName =
  incentiveNavActionHandlerCreator(null).type;

export const incentiveNavSearchQueryCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/INCENTIVE_NAV_SEARCH_QUERY_CREATOR',
);
export const incentiveNavSearchQueryCreatorTypeName =
  incentiveNavSearchQueryCreator(null).type;

export const incentiveSaveCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/INCENTIVE_NAV_SAVE_QUERY_CREATOR',
);
export const incentiveSaveCreatorTypeName = incentiveSaveCreator(null).type;

export const incentiveNavToggleCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/UPDATE_INCENTIVE_NAV_TOGGLE',
);

export const inactiveNavbarDropdownCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/INCENTIVE_NAV_GET_STATE_DATA',
);
export const inactiveNavbarDropdownCreatorTypeName =
  inactiveNavbarDropdownCreator(null).type;

export const inactiveNavbarDropdownDivisionCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/INCENTIVE_NAV_GET_DIVISION_DATA',
);
export const inactiveNavbarDropdownDivisionCreatorTypeName =
  inactiveNavbarDropdownDivisionCreator(null).type;

export const inactiveNavbaFilterIconCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/INCENTIVE_NAV_Filter_Icon_STATUS',
);
export const inactiveNavbarFilterIconCreatorTypeName =
  inactiveNavbaFilterIconCreator(null).type;

export const incentiveNavToggleCreatorTypeName =
  incentiveNavToggleCreator(null).type;

export const incentiveUpdateCurrentPageCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/UPDATE_UPDATE_CURRENT_PAGE_CREATOR',
);

export const incentiveUpdateCurrentPageCreatorTypeName =
  incentiveUpdateCurrentPageCreator(null).type;

// incentive distribution slice
export const fetchYearlyQuartersCreator = createAction(
  'SALES_TRANSFER/FETCH_YEARLY_QUARTERS',
);
export const fetchYearlyQuartersTypeName = fetchYearlyQuartersCreator().type;

export const fetchFromHQCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_FROM_HQ',
);
export const fetchFromHQTypeName = fetchFromHQCreator(null).type;

export const fetchToHQCreator = createAction<any>('SALES_TRANSFER/TO_FROM_HQ');
export const fetchToHQTypeName = fetchToHQCreator(null).type;

export const fetchSLMCreator = createAction<any>('SALES_TRANSFER/FETCH_SLM');
export const fetchSLMTypename = fetchSLMCreator(null).type;

export const fetchPoolCreator = createAction<any>('SALES_TRANSFER/FETCH_POOL');
export const fetchPoolTypename = fetchPoolCreator(null).type;

export const fetchfromPoolCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_GRP_OF_POOL',
);
export const fetchfromPoolTypename = fetchfromPoolCreator(null).type;

export const fetchSalesTransferDataCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_SALES_TRANSFER_DATA',
);
export const fetchSalesTransferDataTypename =
  fetchSalesTransferDataCreator(null).type;

export const fetchSalesTransferGoBtnCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_SALES_TRANSFER_GO_BTN',
);
export const fetchSalesTransferGoBtnTypename =
  fetchSalesTransferGoBtnCreator(null).type;

export const fetchGroupPoolDataCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_GROUP_POOL_DATA',
);
export const fetchGroupPoolDataTypename = fetchGroupPoolDataCreator(null).type;

export const fetchSLMSalesDistributionDataCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_SLM_SALES_DISTRIBUTION_DATA',
);
export const fetchSLMSalesDistributionDataTypename =
  fetchSLMSalesDistributionDataCreator(null).type;

export const fetchPoolSalesDistributionDataCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_POOL_SALES_DISTRIBUTION_DATA',
);
export const fetchPoolSalesDistributionDataTypename =
  fetchPoolSalesDistributionDataCreator(null).type;

/***** sales distribution  */
export const fetchsalesDistributionListDataCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_SALES_DISTRIBUTION_LIST_DATA',
);
export const fetchsalesDistributionListDataTypename =
  fetchsalesDistributionListDataCreator(null).type;

export const fetchsalesDistributionRemarksDataCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_SALES_DISTRIBUTION_REMARKS_DATA',
);
export const fetchsalesDistributionRemarksDataTypename =
  fetchsalesDistributionRemarksDataCreator(null).type;

export const fetchdDownloadExcelURLSalesTransferCreator = createAction<any>(
  'SALES_TRANSFER/FETCH_DOWNLOAD_EXCEL_URL_SALES_TRANSFER',
);
export const fetchDownloadExcelURLSalesTransferTypeName =
  fetchdDownloadExcelURLSalesTransferCreator(null).type;

export const fetchdDownloadExcelURLSalesDistributionCreator = createAction<any>(
  'SALES_DISTRIBUTION/FETCH_DOWNLOAD_EXCEL_URL_SALES_DISTRIBUTION',
);
export const fetchDownloadExcelURLSalesDistributionTypeName =
  fetchdDownloadExcelURLSalesDistributionCreator(null).type;

const incentiveSlice = createSlice({
  name: 'INCENTIVE-CONFIGURATION',
  initialState: initialState,
  reducers: {
    updateNavData: (state, action) => {
      return {
        ...state,
        nav: action.payload,
      };
    },
    updateModalHandler: (state, action) => {
      return {
        ...state,
        modal: action.payload,
      };
    },
    updateLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateTotalRecords: (state, action) => {
      const {listing, ...props}: {listing: string; count: number} =
        action.payload;
      return {
        ...state,
        pages: {
          ...state.pages,
          [listing]: {
            ...state.pages[listing],
            ...props,
          },
        },
      };
    },
    updateNavSearchQuery: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          search: {
            ...state.nav.search,
            query: action.payload,
          },
        },
      };
    },

    updateSaveQuery: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          save: {
            ...state.nav.save,
            value: action.payload,
          },
        },
      };
    },
    updateCurrentPage: (state, action) => {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    updateNavToggle: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          status: {
            ...state.nav.status,
            value: action.payload,
          },
        },
      };
    },
    updateStatesList: (state, action) => {
      return {
        ...state,
        statesList: action.payload,
      };
    },
    updateDivisionList: (state, action) => {
      return {
        ...state,
        divisionList: action.payload,
      };
    },
    setRefetchData: (state, action) => {
      return {
        ...state,
        reFetchData: action.payload,
      };
    },
    toggleAddingData: (state, action) => {
      return {
        ...state,
        addingResponse: action.payload,
      };
    },
    toggleFetchingData: (state, action) => {
      return {
        ...state,
        fetchingResponse: action.payload,
      };
    },
    setRequestForListData: (state, action) => {
      const formattedResponse = getFormattedRequest(action.payload);
      return {
        ...state,
        requestForList: formattedResponse,
      };
    },
    setDivisionListData: (state, action) => {
      const formattedResponse = getFormattedRequest(action.payload);
      return {
        ...state,
        divisionList: formattedResponse,
      };
    },
    setStateListData: (state, action) => {
      const formattedResponse = getFormattedRequest(action.payload);
      return {
        ...state,
        stateList: formattedResponse,
      };
    },
    toggleClearFilter: (state, action) => {
      return {
        ...state,
        clearFilterData: action.payload,
      };
    },
    setSalesTransferData: (state, action) => {
      return {
        ...state,
        salesTransferData: action.payload,
      };
    },
    setSalesTransferBackupData: (state, action) => {
      return {
        ...state,
        salesTransferBackData: action.payload,
      };
    },

    setClickGoValue: (state, action) => {
      return {
        ...state,
        clickOnGoBtnValue: action.payload,
      };
    },

    setQuarterData: (state, action) => {
      return {
        ...state,
        quarterData: action.payload,
      };
    },
    setFromHQData: (state, action) => {
      return {
        ...state,
        fromHQData: action.payload,
      };
    },
    setToHQData: (state, action) => {
      return {
        ...state,
        toHQData: action.payload,
      };
    },
    setPoolData: (state, action) => {
      return {
        ...state,
        poolData: action.payload,
      };
    },
    setToPoolData: (state, action) => {
      return {
        ...state,
        toPoolData: action.payload,
      };
    },
    setFromPoolFilter: (state, action) => {
      return {
        ...state,
        filterFromPoolData: action.payload,
      };
    },
    setToPoolFilterData: (state, action) => {
      return {
        ...state,
        filterToPoolData: action.payload,
      };
    },
    setFromPoolData: (state, action) => {
      return {
        ...state,
        groupOfPoolData: action.payload,
      };
    },

    setToGroupOfpool: (state, action) => {
      return {
        ...state,
        toGroupOfPoolData: action.payload,
      };
    },
    setSLMData: (state, action) => {
      return {
        ...state,
        slmData: action.payload,
      };
    },
    setSelectedQuarter: (state, action) => {
      return {
        ...state,
        quarterList: action.payload,
      };
    },
    clearSalesTransferState: state => {
      return {
        ...state,
        salesTransferData: null,
        quarterData: [],
        fromHQData: [],
        toHQData: [],
        slmData: [],
        quarterList: null,
        salesDistributionListData: null,
        salesDistributionBackupListData: null,
        remaksData: [],
        setSaleDistributionDiscription: '',
        pages: {},
      };
    },
    clearState: state => {
      return {
        ...state,
        pages: {
          unlockRequest: {
            count: 0,
            pageIndex: 1,
          },
        },
        modal: {
          show: null,
          type: null,
        },
        loading: null,
        currentPage: null,
        statesList: null,
        divisionList: null,
        reFetchData: false,
        addingResponse: false,
        fetchingResponse: false,
        requestForList: null,
        clearFilterData: false,
      };
    },

    updateFilterIcon: (state, action) => {
      return {
        ...state,
        filterIcon: action.payload,
      };
    },

    setSalesTransferLoading: (state, action) => {
      state.loadingSalesTransferData = action.payload;
    },
    setGroupPoolData: (state, action) => {
      return {
        ...state,
        groupPoolList: action.payload,
      };
    },
    setSLMSalesDistributionData: (state, action) => {
      return {
        ...state,
        slmSalesDistributionList: action.payload,
      };
    },
    setPoolSalesDistributionData: (state, action) => {
      return {
        ...state,
        poolSalesDistributionList: action.payload,
      };
    },
    setFilterPoolData: (state, action) => {
      return {
        ...state,
        filterPoolData: action.payload,
      };
    },

    setSalesDistributionListData: (state, action) => {
      return {
        ...state,
        salesDistributionListData: action.payload,
      };
    },
    setSalesDistributionBackupListData: (state, action) => {
      return {
        ...state,
        salesDistributionBackupListData: action.payload,
      };
    },

    setSaleDistributionDiscription: (state, action) => {
      return {
        ...state,
        massage: action.payload,
      };
    },

    setRemarksData: (state, action) => {
      return {
        ...state,
        remaksData: action.payload,
      };
    },
    setDownloadExcelSalesTransferURL: (state, action) => {
      return {
        ...state,
        downloadExcelSalesTransferURL: action.payload,
      };
    },
    setDownloadExcelSalesDistributionURL: (state, action) => {
      return {
        ...state,
        downloadExcelSalesDistributionURL: action.payload,
      };
    },

    setAuditTrailActionHandlerData: (state, action) => {
     return {
      ...state,
      auditTrailActionHandlerData: action.payload
     }
    },

    setRefetchSalesTransfer: (state, action) => {
     return {
      ...state,
      refetchSalesTransfer: Math.random(),
     }
    },

    setRefetchSalesDistribution: (state, action) => {
     return {
      ...state,
      refetchSalesDistribution: Math.random(),
     }
    },

    setAuditTrailExcelData: (state, action) => {
      return {
        ...state,
        auditTrailExcelData: action.payload
      }
    },

    setClearSalesDistributionData: (state, action) => {
      return {
        ...state,
        clearSalesDistribution: action.payload
      }
    },

  },
});

export const incentiveActions = incentiveSlice.actions;
export const incentiveReducer = incentiveSlice.reducer;
