import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import FilterHeader from './FilterHeader';
import {actions, incentiveActions} from '../../redux';
import {incentiveSelector} from '../../redux/selectors';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {routeStateActions} from '@app/router/redux';
import {useCallback, useEffect, useState} from 'react';
import {
  fetchSalesTransferDataCreator,
  fetchSalesTransferGoBtnCreator,
  inactiveNavbaFilterIconCreator,
  incentivePageLists,
  incentiveSaveCreator,
  incentiveUpdateCurrentPageCreator,
} from '../../redux/slice';
import {SalesTransferTable} from './SalesTransferTable';
import {NetworkService, ToasterService} from '@app/services';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {ToastType} from '@app/services/toaster';
import {Box, CircularProgress} from '@mui/material';

let tempdata: any = {};
const SalesTransfer = () => {
  const dispatch = useDispatch();
  const [salesTransferAllData, setSalesTransferAllData] = useState<any>({});
  const [saving, setSaving] = useState<boolean>(false);
  const salesTransferData = useSelector(
    incentiveSelector.getSalesTransferData(),
    shallowEqual,
  );
  const salesTransferbackData = useSelector(
    incentiveSelector.getSalesTransferBackupData(),
    shallowEqual,
  );

  const getLoadingSalesTransferData = useSelector(
    incentiveSelector.getLoadingSalesTransferData(),
  );
  const save = useSelector(incentiveSelector.getSaveStatus, shallowEqual);
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);
  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);

  useEffect(() => {
    let updatedData = tempdata;
    if (save?.value === 'save') {
      dispatch(
        fetchSalesTransferGoBtnCreator({
          isEdited: false,
          isRefreshEditable: false,
        }),
      );
      saveSalesTransferData(updatedData);
    }

    if (save?.value === 'refresh') {
      setSalesTransferAllData(salesTransferbackData);
      tempdata = JSON.parse(JSON.stringify(salesTransferbackData));
    }

    if (save?.value === 'clear') {
      dataClear(updatedData);
    }
    dispatch(incentiveSaveCreator({value: ''}));
  }, [save, salesTransferbackData]);

  const dataClear = async (updatedData: any) => {
    const dataParam = {
      staffPositionId: updatedData?.staffPositionId
        ? updatedData?.staffPositionId
        : 0,
      quarterId: updatedData?.quarterId ? updatedData?.quarterId : 0,
      toGroupPoolId: updatedData?.toGroupPoolId
        ? updatedData?.toGroupPoolId
        : 0,
      fromGroupPoolId: updatedData?.fromGroupPoolId
        ? updatedData?.fromGroupPoolId
        : 0,
      searchKey: search?.query || '',
    };

    const response = await NetworkService.post(
      API_PATH.incentiveConfiguration.incentiveClear,
      updatedData,
      {},
    );

    if (response.status !== 200) {
      ToasterService.showToaster(
        response.data?.description || TOAST_MESSAGES.UNSUCCESSFULL_UPDATE,
        ToastType.ERROR,
      );
    } else {
      ToasterService.showToaster(
        TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
        ToastType.SUCCESS,
      );

      dispatch(fetchSalesTransferDataCreator(dataParam));
    }
  };

  const saveSalesTransferData = async (updatedData: any) => {
    try {
      const dataParam = {
        staffPositionId: updatedData?.staffPositionId
          ? updatedData?.staffPositionId
          : 0,
        quarterId: updatedData?.quarterId ? updatedData?.quarterId : 0,
        toPoolId: updatedData?.toPoolId ? updatedData?.toPoolId : 0,
        toGroupPoolId: updatedData?.toGroupPoolId
          ? updatedData?.toGroupPoolId
          : 0,
        fromPoolId: updatedData?.fromPoolId ? updatedData?.fromPoolId : 0,
        fromGroupPoolId: updatedData?.fromGroupPoolId
          ? updatedData?.fromGroupPoolId
          : 0,
        searchKey: search?.query || '',
      };
      setSaving(true);
      const response = await NetworkService.post(
        API_PATH.incentiveConfiguration.getSalestransferSave,
        updatedData,
        {},
      );

      if (response.status !== 200) {
        ToasterService.showToaster(
          TOAST_MESSAGES.UNSUCCESSFULL_UPDATE,
          ToastType.ERROR,
        );
      } else {
        ToasterService.showToaster(
          TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
          ToastType.SUCCESS,
        );
        setSaving(false);
        dispatch(fetchSalesTransferDataCreator(dataParam));
      }
    } catch (error: any) {
      console.log(error);
      setSaving(false);
    }
  };

  const sortSalesTransferSegmentListData = (data: any = {}) => {
    const newData = {...data};
    if (!data?.segmentList) return data;
    const segmentList = [...newData.segmentList] ?? [];

    const sortedArray = segmentList
      .filter(
        item => item.month1 !== 0 || item.month2 !== 0 || item.month3 !== 0,
      )
      .concat(
        segmentList.filter(
          item => item.month1 === 0 && item.month2 === 0 && item.month3 === 0,
        ),
      );
    newData.segmentList = sortedArray;
    return newData;
  };

  useEffect(() => {
    tempdata = JSON.parse(
      JSON.stringify(sortSalesTransferSegmentListData(salesTransferData)),
    );
    setSalesTransferAllData(
      sortSalesTransferSegmentListData(salesTransferData),
    );
  }, [salesTransferData]);

  useEffect(() => {
    dispatch(
      fetchSalesTransferGoBtnCreator({
        isEdited: false,
        isRefreshEditable: false,
      }),
    );
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.incentiveConfiguration,
      ),
    );

    dispatch(
      inactiveNavbaFilterIconCreator({
        value: false,
        color: 'gray',
        gobtnClick: false,
      }),
    );

    dispatch(
      incentiveActions.updateNavData({
        title: 'Sales Transfer',
        search: {placeholder: '', query: ''},
        download: {
          title: 'Download',
          type: actions.addSalesTransfer,
          save: 'Save',
          refresh: 'Refresh',
          clear: 'clear',
          upload: 'upload',
          showSearchBarWithToggle: 'showSearchBarWithToggle',
          search: {
            placeholder: 'search by group name or product name',
            query: '',
          },
        },
      }),
    );
    dispatch(
      incentiveUpdateCurrentPageCreator(incentivePageLists.salesTransfer),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(incentiveActions.clearSalesTransferState());
      dispatch(incentiveActions.setSalesTransferData([]));
    };
  }, []);

  const updateSubSegment = (_val: any, data: any): void => {
    _val.month1 = parseInt(data.month1) ? parseInt(data.month1) : 0;
    _val.month2 = parseInt(data.month2) ? parseInt(data.month2) : 0;
    _val.month3 = parseInt(data.month3) ? parseInt(data.month3) : 0;
    _val.incentiveAmount = data?.incentiveAmount ? data?.incentiveAmount : 0;
    _val.remarks = data.remarks;
  };

  const updateSubProduct = (_val: any, data: any): void => {
    _val.month1 = parseInt(data.month1) ? parseInt(data.month1) : 0;
    _val.month2 = parseInt(data.month2) ? parseInt(data.month2) : 0;
    _val.month3 = parseInt(data.month3) ? parseInt(data.month3) : 0;
    _val.remarks = data.remarks;
  };

  const updateSegmentList = (segment: any, data: any): void => {
    let inctAmt = 0;
    let m1 = 0;
    let m2 = 0;
    let m3 = 0;

    if (segment.subSegment?.length > 0) {
      segment.subSegment.forEach((_val: any, _index: number) => {
        if (_val.id === data.id) {
          updateSubSegment(_val, data);
        }

        inctAmt += _val.incentiveAmount;
        m1 += Number(_val.month1) || 0;
        m2 += Number(_val.month2) || 0;
        m3 += Number(_val.month3) || 0;
      });
    } else if (segment.subProduct?.length > 0) {
      segment.subProduct.forEach((_val: any, _index: number) => {
        if (_val.id === data.id) {
          updateSubProduct(_val, data);
        }

        m1 += Number(_val.month1) || 0;
        m2 += Number(_val.month2) || 0;
        m3 += Number(_val.month3) || 0;
      });
    }

    segment.incentiveAmount = inctAmt;
    segment.month1 = m1;
    segment.month2 = m2;
    segment.month3 = m3;
  };

  const onUpdateHandle = useCallback(
    (data: any): void => {
      tempdata?.segmentList?.forEach((segment: any) => {
        if (segment?.id === data?.segmentId) {
          updateSegmentList(segment, data);
        }
      });

      const updatedSalesTransferAllData: any = {...tempdata};
      setSalesTransferAllData(updatedSalesTransferAllData);
    },
    [salesTransferAllData],
  );

  useEffect(() => {
    const params = {
      staffPositionId: 0,
      quarterId: 0,
      toPoolId: 0,
      toGroupPoolId: 0,
      fromPoolId: 0,
      fromGroupPoolId: 0,
      searchKey: '',
    };
    // dispatch(fetchdDownloadExcelURLSalesTransferCreator(params));
  }, []);

  return (
    <div>
      <FilterHeader />

      {getLoadingSalesTransferData ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '60vh',
          }}>
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : (
        salesTransferAllData && (
          <SalesTransferTable
            salesTransferAllData={salesTransferAllData}
            showDisclaimer={salesTransferData?.showDisclaimer}
            isEmpty={
              !(
                salesTransferData?.segmentList &&
                salesTransferData?.segmentList?.length > 0
              )
            }
            onUpdateHandler={onUpdateHandle}
          />
        )
      )}
      {saving ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 10
          }}>
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : null}
    </div>
  );
};

export default SalesTransfer;
