import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Dropdown from '@app/components/dropdown';
import {Styles} from './stlye';

import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import {
  salesTransferConfigurationCreator,
  salesTransferConfigurationSelector,
} from '../redux';
import {incentiveActions} from '@app/screens/incentive-configuration/redux';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const styles = {
  listItemText: {
    color: '#1e1c3b',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    marginLeft: 1,
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuPropsConfig = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

enum FilterType {
  division = 'division',
  state = 'state',
  unlockTillDate = 'unlockTillDate',
  requestFor = 'requestFor',
  quarter = 'quarter',
  configuration = 'configuration',
}

let flag = false;
let temp: any[] = [];
function FilterHeader(props: any) {
  const [selectedDivision, setSelectedDivision] = useState<any>([]);
  const [quarter, setQuarter] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [con, setCon] = useState<{label: string; value: number} | undefined>(
    undefined,
  );
  const [selectedFilter, setSelectedFilter] = useState({
    stateId: null,
    divisionId: null,
    quarter: null,
    configuration: null,
  });

  const [isDivisionSelected, setIsDivisionSelected] = useState(false);
  const [divisionName, setDivisionName] = useState<string[]>([]);
  const [selectedAllDivision, setSelectedAllDivision] = useState(flag);
  const [searchtxt, setSearchtxt] = useState<any>('');
  const [divisionListArr, setDivisionListArr] = useState<any>([]);

  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const clearFilterData = useSelector(incentiveSelector.clearFilterData());
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);

  const quarterList = useSelector(
    salesTransferConfigurationSelector.getSalesTransferConfigurationQuarterData(),
  );
  const conList = useSelector(
    salesTransferConfigurationSelector.getSalesTransferConfigurationConData(),
  );
  useEffect(() => {
    if (clearFilterData) {
      resetData();
    }
  }, [clearFilterData]);

  const dispatch = useDispatch();

  const resetData = () => {
    setQuarter(undefined);
    setCon(undefined);
    dispatch(incentiveActions.toggleClearFilter(false));
  };

  const filterHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      /**   for Division list */
      const findIdList = (arrayOfObj: any, arrayValue: any) => {
        const result = arrayValue.map((label: any) => {
          const item = arrayOfObj.find((entry: any) => entry.label === label);
          return item ? item.value : 0;
        });

        return result;
      };
      const divisionIdList = findIdList(divisionList, selectedDivision);

      /**   for state list */

      const requestParams: any = {
        pageIndex: 1,
        isActive: isActive === 1,
        pageSize: 15,
        quarterId: quarter?.value,
        quarter: search?.query,
        configurationId: con?.value,
        divisionIdList: divisionIdList,
      };

      props.clickOnGoBtn(requestParams);
      if (divisionIdList?.length > 0) {
        setIsDivisionSelected(false);
        dispatch(salesTransferConfigurationCreator(requestParams));
      } else {
        setIsDivisionSelected(true);
      }
    },
    [isActive, quarter, con, selectedDivision],
  );

  const onChangefilterHandler = useCallback(
    (val: any, typeOfFilter: FilterType) => {
      let value: any;
      switch (typeOfFilter) {
        case FilterType.division:
          value = {
            ...selectedFilter,
            divisionId: val?.value,
          };
          break;
        case FilterType.state:
          value = {
            ...selectedFilter,
            stateId: val?.value,
          };
          break;
        case FilterType.quarter:
          value = {
            ...selectedFilter,
            quarter: val?.label,
          };
          break;
        case FilterType.configuration:
          value = {
            ...selectedFilter,
            configuration: val?.label,
          };
          break;
      }
      setSelectedFilter(value);
    },
    [selectedFilter],
  );

  /****************        multi select state drop down   ***********************/
  const onChangeStateValue = useCallback(selected => {
    if (selected.length === 0) {
      return <em style={Styles.placeHolder}>Select</em>;
    }

    return selected.join(', ');
  }, []);

  /****************        multi select division drop down   ***********************/
  const onChangeDivision = useCallback(
    (event: any) => {
      const {
        target: {value},
      } = event;

      let text = typeof value === 'string' ? value.split(',') : value

      if(text.length === 1 && text[0] === undefined){
        setSelectedAllDivision(!selectedAllDivision)
      }else {
      setDivisionName(text);

      if (selectedDivision?.length !== event.target.value?.length) {
        setSelectedAllDivision(false);
        setSelectedDivision(event.target.value);
      }

      if (divisionList?.length === event.target.value?.length) {
        setSelectedAllDivision(true);
        setSelectedDivision(event.target.value);
      }
    }
    },
    [selectedDivision, selectedAllDivision, divisionName, divisionList],
  );

  const SelectAllChange = () => {
    if (selectedAllDivision) {
      setSelectedAllDivision(false);
    } else {
      setSelectedAllDivision(true);
    }
  };

  useEffect(() => {
    let temp: any = [];

    if (selectedAllDivision) {
      divisionList?.forEach((element: any) => {
        temp.push(element.label);
      });
      setSelectedDivision(temp);
      setDivisionName(temp);
    }

    if (
      !selectedAllDivision &&
      selectedDivision?.length - 1 === divisionList?.length
    ) {
      setDivisionName([]);
      setSelectedDivision([])
    }
  }, [selectedAllDivision]);

  const onChangeSearchTextFiled = useCallback((e: any) => {
    let temp = [...divisionList];
    setSearchtxt(e.target.value);
    let searchdata = temp?.filter((val: any) => {
      return val?.label?.toLowerCase().includes(e.target.value);
    });

    setDivisionListArr(searchdata);
  }, []);

  const clearSearchText = useCallback(() => {
    setSearchtxt('');
    setDivisionListArr(divisionList);
  }, [divisionListArr]);

  useEffect(() => {
    temp = JSON.parse(JSON.stringify(divisionList));
    setDivisionListArr(JSON.parse(JSON.stringify(divisionList)));
  }, [divisionList]);
  return (
    <div style={Styles.filterHeaderBody}>
      <div style={Styles.filterBox}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
          mr={1}
        >
          {!isDivisionSelected ? null : (
            <Box sx={{color: 'red', position: 'absolute', marginTop: -2}}>
              {'Select Division!!'}
            </Box>
          )}
          <p style={Styles.text}>DIVISION</p>

          <FormControl sx={{width: 200}}>
            <Select
              multiple
              value={divisionName}
              onChange={onChangeDivision}
              renderValue={onChangeStateValue}
              MenuProps={MenuPropsConfig}
              displayEmpty
              sx={{backgroundColor: '#FFFFFF'}}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  value={searchtxt}
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    style: {
                      height: 30,
                      fontSize: 11,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{cursor: 'pointer'}}
                        onClick={clearSearchText}
                      >
                        <ClearIcon sx={{fontSize: 15}} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={onChangeSearchTextFiled}
                  onKeyDown={e => {
                    if (e.key !== 'Escape') {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem onChange={SelectAllChange}>
                <Checkbox value={'all'} checked={selectedAllDivision} />
                <ListItemText
                  primary={
                    <Typography sx={styles.listItemText}>
                      {selectedAllDivision ? 'UnSelect All' : 'Select All'}
                    </Typography>
                  }
                />
              </MenuItem>
              {divisionListArr?.map((val: any) => (
                <MenuItem key={val.value} value={val.label}>
                  <Checkbox checked={divisionName.indexOf(val.label) > -1} />
                  <ListItemText
                    primary={
                      <Typography sx={styles.listItemText}>
                        {val?.label}
                      </Typography>
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            width: '18%',
          }}
          mr={1}
        >
          <p style={Styles.text}>QUARTER</p>
          <Box width='100%' data-testid={'selectState-dropdown'}>
            <Dropdown
              onChange={(val: any) => {
                onChangefilterHandler(val, FilterType.quarter);
                setQuarter(val);
              }}
              value={quarter}
              dropdownList={quarterList}
              placeholder="Select Quarter"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            // marginLeft: 1.5,
            width: '20%',
          }}
          mr={1}
        >
          <p style={Styles.text}>CONFIGURATION</p>
          <Box width='100%' data-testid={'selectState-dropdown'}>
            <Dropdown
              onChange={(val: any) => {
                onChangefilterHandler(val, FilterType.configuration);
                setCon(val);
              }}
              value={con}
              dropdownList={conList}
              placeholder="Select Configuration"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Button
            data-testid="clickGo-icon"
            variant="contained"
            onClick={filterHandler}
            size="small"
            style={{
              fontFamily: ['Poppins'].join(','),
              fontSize: 12.7,
              padding: '11px',
              cursor: 'pointer',
              letterSpacing: 0,
              textAlign: 'center',
              backgroundColor: '#322b7c',
              color: 'white',
              border: 0,
              borderRadius: 10,
              minWidth: 50,
              maxHeight: 50,
              marginTop: 42,
            }}
          >
            Go
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default React.memo(FilterHeader);
